import { useEffect, useState } from "react";
import { hyDebug } from "./hyDebug";
import { app as microsoftTeams } from "@microsoft/teams-js";
import { getNullSubstring, getSSOToken } from "./epCommon";
import { backendGetUserToken, backendUpdateLogin, getUserHashClient } from "./epBackend";
import { DayOfWeek } from "@fluentui/react-calendar-compat";

export enum NoAccessReason {
    none = 'none',
    not_in_teams = 'not_in_teams',
    error_token_retreival = 'error_token_retreival',
    error_no_access = 'error_no_access',
    error_currently_down = 'error_currently_down',
    error_no_user = 'error_no_user',
    error_other = 'error_other'
}

export class AppAuthorizationData {
    loading: boolean = true;

    accessGranted: boolean = false;
    noAccessReason: NoAccessReason = NoAccessReason.none;

    userUPN: string = "";
    userID: string = "";
    tenantID: string = "";
    host: string = "";
    displayName: string = "";
    lang: string = "";
    locale: string = "";

    sso_required: boolean = false;
    userToken: string = "";

    hasActiveLicense: boolean = false;
    firstDayOfWeek: number = DayOfWeek.Sunday;
    pollLimit: boolean = false;

    showBetaFeatures: boolean = false;
}

export function useAppAuthorization(loading: boolean | undefined, inTeams: boolean | undefined, authRefreshTicker: number, isSSOEnabled: boolean, setIsSSOEnabled: (val: boolean) => void): { data: AppAuthorizationData } {
    const [data, setData] = useState<AppAuthorizationData>(new AppAuthorizationData());

    useEffect(() => {

        let active = true;

        // declare the data fetching function
        const fetchData = async () => {

            try {

                if (!active) { return; }

                const dataReturn = new AppAuthorizationData();

                // ###############################################################################
                // Check basic teamsfx data and set teamsfx / teams-js data
                // ###############################################################################

                // As long as teams-react-lib is loading, we will return immediately.
                if (loading === true || loading === undefined) {
                    dataReturn.loading = true;
                    setData(dataReturn);
                    return;
                }

                try {
                    if (inTeams) {
                        await microsoftTeams.initialize();
                    } else {
                        throw new Error("Not running in teams.");
                    }
                } catch (ex) {

                    dataReturn.noAccessReason = NoAccessReason.not_in_teams;

                    dataReturn.loading = false;
                    setData(dataReturn);
                    return;
                }

                // ###############################################################################
                // Get User Credentials / Hash / Token
                // ###############################################################################

                if (!microsoftTeams.isInitialized()){
                    await microsoftTeams.initialize();
                    hyDebug("Teams-JS initialized.");
                }
                
                const inner_context = await microsoftTeams.getContext();

                dataReturn.userUPN = inner_context.user?.userPrincipalName ?? "not set";
                dataReturn.userID = inner_context.user?.id ?? "not set";
                dataReturn.tenantID = inner_context.user?.tenant?.id ?? "not set";
                dataReturn.host = (inner_context.app.host.clientType ?? "not set") + "\\" + (inner_context.app.host.name ?? "not set");
                dataReturn.displayName = (inner_context.user?.displayName ?? "");
                
                dataReturn.locale = inner_context.app.locale ? inner_context.app.locale : "en-US";
                dataReturn.lang = getNullSubstring(dataReturn.locale, 0, 2);

                if (!dataReturn.userID || !dataReturn.tenantID) {
                    hyDebug("Guest account users are not supported.");

                    dataReturn.noAccessReason = NoAccessReason.error_no_user;
                    dataReturn.loading = false;
                    setData(dataReturn);
                    return;
                }

                hyDebug("Deeplink (next line): ");
                console.log(inner_context.page);

                // Emergency shut down for all users not from z0z75.onmicrosoft.com
                // Currently not in use.
                /*
                if (!dataReturn.userUPN.toLowerCase().includes("z0z75.onmicrosoft.com")){
                    dataReturn.noAccessReason = NoAccessReason.error_currently_down;
                    dataReturn.loading = false;
                    setData(dataReturn);
                    return;
                }
                */

                const user_hash = getUserHashClient(dataReturn.userUPN, dataReturn.userID, dataReturn.tenantID, dataReturn.displayName);
                const resultGetUserToken = await backendGetUserToken(dataReturn.userUPN, dataReturn.userID, user_hash);

                // Check if all features shall be shown.
                dataReturn.showBetaFeatures = _determineShowBetaFeatures(dataReturn.userUPN);


                if (!resultGetUserToken.success) {
                    hyDebug("Token could not received: User hash: " + user_hash);

                    dataReturn.noAccessReason = NoAccessReason.error_token_retreival;
                    dataReturn.loading = false;
                    setData(dataReturn);
                    return;
                }

                // Store user token:
                dataReturn.userToken = resultGetUserToken.token;

                // ###############################################################################
                // Update Login
                // ###############################################################################
                try {
                    const sso_token = await getSSOToken(isSSOEnabled, setIsSSOEnabled, true);
                    if (sso_token) { hyDebug("SSO token received."); } else { hyDebug("SSO token not received."); }

                    const resultUpdateLogin = await backendUpdateLogin(dataReturn.userUPN, dataReturn.userID, dataReturn.userToken, sso_token, dataReturn.lang, dataReturn.locale, dataReturn.host);
                    if (resultUpdateLogin.success !== true) {
                        throw Error();
                    }
                    // Take over properties from update login:
                    dataReturn.sso_required = resultUpdateLogin.sso_required;
                    dataReturn.hasActiveLicense = resultUpdateLogin.has_active_license;
                    dataReturn.firstDayOfWeek = resultUpdateLogin.firstDayOfWeek;
                    dataReturn.pollLimit = resultUpdateLogin.poll_limit;
                } catch {
                    hyDebug("Access restricted.");

                    dataReturn.noAccessReason = NoAccessReason.error_no_access;
                    dataReturn.loading = false;
                    setData(dataReturn);
                    return;
                }


                // ###############################################################################
                // Grant access!
                // ###############################################################################

                dataReturn.accessGranted = true;

                dataReturn.loading = false;
                setData(dataReturn);
                return;


            } catch (ex) {
                const dataReturn = new AppAuthorizationData();

                dataReturn.accessGranted = false;
                dataReturn.noAccessReason = NoAccessReason.error_other;

                dataReturn.loading = false;
                setData(dataReturn);
                return;
            }
        }

        // call the function
        fetchData().catch(console.error);
        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, authRefreshTicker]);

    return { data };

}

function _determineShowBetaFeatures(user_upn: string): boolean {

    const user_upn_lower = user_upn.toLowerCase();

    // Show all features to demo admin accoutn.
    if (user_upn_lower.includes("hyo.admin@z0z75.onmicrosoft.com")) { return true; }

    return false;
}
import { useContext, useEffect, useRef, useState } from "react";

import { Button, Title3, Link, Caption1 } from "@fluentui/react-components";
import { PollHorizontalRegular, PremiumRegular, PersonKeyRegular } from "@fluentui/react-icons";

import { MainContainer } from "./components/MainContainer";
import { ImprintTab, ProTag } from "./components/CommonMisc";

import { EmptyFlexRow, Flex, FlexItem } from "./blocks/FlexBoxes";

import { AppContext, EnumAppEnvironment } from "./context/AppContext";
import { TabContext, TabContextClass, TabDialogType } from "./context/TabContext";

import { ListPollResults } from "./library/epBackendTypes";
import { PollDataClass } from "./bot-client-shared/PollDataClass";
import { TabDialogGetStarted } from "./components/TabDialogGetStarted";
import { ListPollsTable } from "./components/ListPollsTable";
import { TabDialogDeletePoll } from "./components/TabDialogDeletePoll";
import { ListPollsSearch } from "./components/ListPollsSearch";
import { TabDialogViewPoll } from "./components/TabDialogViewPoll";
import { TabDialogCreateDraft } from "./components/TabDialogCreateDraft";
import { TabDialogEditVote } from "./components/TabDialogEditVote";
import { isMobile } from "react-device-detect";
import { TabDialogManageLicense } from "./components/TabDialogManageLicense";
import { GetOrCreateAnonymGUID, backendTrackEvent } from "./library/epBackend";


export default function EntryTab() {

    // App context:
    const appContext = useContext(AppContext);
    appContext.environment.current = EnumAppEnvironment.personal_tab;

    // Create variables for tab context
    const [tabDialog, setTabDialog] = useState(TabDialogType.None);

    const listPollResults = useRef<ListPollResults>(new ListPollResults());

    const tableSearch = useRef<string>("");
    const activeTablePollElement = useRef<PollDataClass>(new PollDataClass());

    const [refreshTickerData, setRefreshTickerData] = useState(1);
    const [refreshTickerGUI, setRefreshTickerGUI] = useState(1);

    const tabContext: TabContextClass = {
        tabDialog: tabDialog,
        setTabDialog: setTabDialog,

        listPollResults: listPollResults,

        tableSearch: tableSearch,
        activePollElement: activeTablePollElement,

        refreshTickerData: refreshTickerData,
        setRefreshTickerData: setRefreshTickerData,
        refreshTickerGUI: refreshTickerGUI,
        setRefreshTickerGUI: setRefreshTickerGUI,
    };

    // Define intial dialogs.
    useEffect(() => {

        backendTrackEvent(appContext.appAuthData.lang, "Tab", "ListPolls", "", appContext);


        if (!appContext.viewSettings.storage.hideGetStartedOnStartup) {
            // Show get started dialog if not hidden by default
            setTabDialog(TabDialogType.GetStarted);
            return;
        }
    }, []);

    return (

        <TabContext.Provider value={tabContext}>
            <MainContainer>
                <Flex column fillFlex gap="small" alignItems="center" padding="medium">
                    <Flex column fillFlex gap="smaller" style={{ width: (isMobile ? "90%" : "80%"), maxWidth: "1000px", overflow: 'auto' }}>

                        {(tabDialog === TabDialogType.GetStarted) && (<TabDialogGetStarted />)}
                        {(tabDialog === TabDialogType.CreateDraft || tabDialog === TabDialogType.DuplicatePoll) && (<TabDialogCreateDraft />)}
                        {(tabDialog === TabDialogType.DeletePoll) && (<TabDialogDeletePoll />)}
                        {(tabDialog === TabDialogType.ViewOrPostPoll) && (<TabDialogViewPoll />)}
                        {(tabDialog === TabDialogType.EditVote) && (<TabDialogEditVote />)}
                        {(tabDialog === TabDialogType.ManageLicense) && (<TabDialogManageLicense />)}
                        <EmptyFlexRow size="1.0" />

                        <Flex alignItems="center" gap="medium" wrap>
                            <Title3>Poll Overview</Title3>
                            {appContext.appAuthData.hasActiveLicense && <ProTag />}
                            <FlexItem push>
                                <Link onClick={() => setTabDialog(TabDialogType.GetStarted)}>Get started</Link>
                            </FlexItem>
                            <ListPollsSearch />
                            <Button icon={<PollHorizontalRegular />} appearance="primary" onClick={() => setTabDialog(TabDialogType.CreateDraft)}>Draft a new poll</Button>

                            {/* appearance: transparent and style: style={{ paddingLeft: "0px", minWidth: "fit-content" }} */}
                            {!appContext.appAuthData.hasActiveLicense && (<Button icon={<PremiumRegular />} appearance="secondary" onClick={() => setTabDialog(TabDialogType.ManageLicense)} title="Upgrade to Pro plan">Upgrade</Button>)}
                            {appContext.appAuthData.hasActiveLicense && (<Button icon={<PersonKeyRegular />} appearance="secondary" onClick={() => setTabDialog(TabDialogType.ManageLicense)} title="Manage your license">Manage</Button>)}

                        </Flex>
                        <Caption1>Presents the polls you've either created or cast a vote on.</Caption1>

                        <EmptyFlexRow size="1.5" />

                        <ListPollsTable />

                    </Flex>
                    <EmptyFlexRow size="0.25" />
                    <ImprintTab />
                </Flex>
            </MainContainer >
        </TabContext.Provider>

    );
}





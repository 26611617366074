import { Text, Button,  Title3} from "@fluentui/react-components";
import { CopyRegular, SendRegular} from "@fluentui/react-icons";
import { EmptyFlexRow, Flex, FlexItem } from "../blocks/FlexBoxes";
import { EnumPollWorkflow, PollContext } from "sub/context/PollContext";
import {  useContext, useEffect } from "react";
import { ListPollsTable } from "./ListPollsTable";
import { TabContext } from "sub/context/TabContext";
import { ListPollsSearch } from "./ListPollsSearch";
import {  backendTrackEvent } from "sub/library/epBackend";
import { AppContext } from "sub/context/AppContext";
import { ProTag } from "./CommonMisc";


export function PollReuseTable() {

    const appContext = useContext(AppContext);
    const pollContext = useContext(PollContext);

    useEffect(() => {
        // Track events
        backendTrackEvent(appContext.appAuthData.lang, "Chat", "ListPolls", "", appContext);
    }, []);

    return (
        <>
            {/* Title Content */}
            <Flex alignItems="center" gap="medium" wrap wrapAlignContent="flex-start">
                <Title3>Post an existing poll</Title3>
                <FlexItem push>
                    <ListPollsSearch />
                </FlexItem>
            </Flex>
            <EmptyFlexRow size="1.0" />

            {/* Main Content */}
            <Text wrap>You can post an existing poll / draft (<SendRegular />) to continue the poll, or create a new poll by duplicating it (<CopyRegular />).</Text>
            <EmptyFlexRow size="0.5" />
            <Flex column fillHeight gap="small" style={{ overflow: 'auto', paddingRight: "0.5em" }}>
                <ListPollsTable />
            </Flex>

            {/* Button Footer */}
            <EmptyFlexRow size="0.5" />

            <FlexItem column push>
                <Flex justifyContent="flex-end" gap="medium">
                    <Button onClick={() => { pollContext.setPollWorkflow(EnumPollWorkflow.task_selector) }}>Back</Button>
                </Flex>
            </FlexItem>
        </>
    );
}
import React from "react";
import { PollDataClass } from "../bot-client-shared/PollDataClass";

export enum EnumPollWorkflow {
    none,
    task_selector,
    close,

    duplicatePoll_loading,
    newPoll_entry,
    newPoll_preview,
    newPoll_preview_close,

    reusePoll_loading, // only chat
    reusePoll_table, // only chat
    reusePoll_preview // only chat
}

export class PollContextClass {
    public pollData : PollDataClass = new PollDataClass();
    public updatePollData : (updates: Partial<PollDataClass>) => void = () => undefined;

    public pollWorkflow : EnumPollWorkflow = EnumPollWorkflow.none;
    public setPollWorkflow : React.Dispatch<React.SetStateAction<EnumPollWorkflow>>  = () => undefined;

}

export const PollContext = React.createContext<PollContextClass>(new PollContextClass());
import { EmptyFlexRow, Flex, FlexItem } from "sub/blocks/FlexBoxes";
import { Button, Text, DataGrid, DataGridHeader, DataGridRow, DataGridBody, DataGridCell, DataGridHeaderCell, TableColumnDefinition, createTableColumn, TableColumnSizingOptions, Input, useId, Menu, MenuTrigger, MenuPopover, MenuList, MenuItem, tokens, InfoLabel } from "@fluentui/react-components";
import { DocumentLockRegular, CopyRegular, WarningFilled, SendRegular, DeleteRegular, EditRegular, PersonEditRegular, VoteRegular, LockOpenRegular, LockClosedRegular, LockClosedFilled, EyeRegular, MoreHorizontalFilled } from "@fluentui/react-icons";
import { useContext, useEffect } from "react";
import { AppContext, EnumAppEnvironment } from "sub/context/AppContext";
import { TabContext, TabDialogType } from "sub/context/TabContext";
import { backendEditPollClient, backendListPolls } from "sub/library/epBackend";
import { getSSOToken, hyDate, refreshTicker, transformDateToDayMonthYear } from "sub/library/epCommon";
import { PollDataClass } from "sub/bot-client-shared/PollDataClass";
import Segment from "sub/blocks/Segment";
import { ListPollResults } from "sub/library/epBackendTypes";
import { isMobile } from "react-device-detect";
import { WarningFreePlanRestriction, WarningRestrictionUnlocked } from "./CommonMisc";

export function ListPollsTable() {

    // Get relevant context information
    const appContext = useContext(AppContext);
    const tabContext = useContext(TabContext);


    // Define table columns and redering functions for the ListPolls table (data grid).
    const tableColumns: TableColumnDefinition<PollDataClass>[] = [
        createTableColumn<PollDataClass>({
            columnId: "index",
            compare: (a, b) => (a.seq - b.seq),
            renderHeaderCell: () => (<b>#</b>),
            renderCell: (item) => item.seq,
        }),
        ...(isMobile ? [] : [
            createTableColumn<PollDataClass>({
                columnId: "created_on",
                compare: (a, b) => a.created_on.localeCompare(b.created_on),
                renderHeaderCell: () => (<b>Created</b>),
                renderCell: (item) => transformDateToDayMonthYear(appContext.appAuthData.locale, hyDate(item.created_on)),
            })
        ]),
        ...(isMobile ? [] : [
            createTableColumn<PollDataClass>({
                columnId: "role",
                compare: (a, b) => getPollRole(a.user_is_creator).localeCompare(getPollRole(b.user_is_creator)),
                renderHeaderCell: () => (<b>Role</b>),
                renderCell: (item) => getPollRole(item.user_is_creator),
            })
        ]),
        createTableColumn<PollDataClass>({
            columnId: "poll",
            compare: (a, b) => a.question.localeCompare(b.question),
            renderHeaderCell: () => (<b>Poll</b>),
            renderCell: (item: PollDataClass) => (
                <Flex wrap fillFlex gap="small" alignItems="center">
                    <Flex column gap="smaller" style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}>
                        {/* Question  */}
                        <Flex wrap gap="smaller" alignItems="center">{item.question} {item.poll_results_restricted && (<WarningFreePlanRestriction />)} {item.poll_unlocked_until && (<WarningRestrictionUnlocked appContext={appContext} dateUntil={item.poll_unlocked_until} />)}</Flex>

                        {/* Additional information  */}
                        <Flex gap="smaller" alignItems="center" wrap><PersonEditRegular />
                            <Text weight="semibold">Creator:</Text> <Text>{item.created_by}</Text>
                            | {item.poll_is_closed ? (<><LockClosedFilled /> <Text weight="semibold">Closed</Text></>) : (<><LockOpenRegular /><Text>Open</Text></>)}
                            | <VoteRegular /> <Text weight="semibold">Votes:</Text> {item.poll_votes_total}
                            {item.option_results_creator_only && (<> | <DocumentLockRegular /> <InfoLabel weight="semibold" label="Creator only" info="The results can only be seen by the poll creator." /></>)}
                        </Flex>
                    </Flex>

                    {appContext.environment.current === EnumAppEnvironment.personal_tab && (
                        <>
                            <FlexItem push>
                                <Button appearance="transparent" icon={<EyeRegular />} title="View poll results" onClick={() => { _viewPoll(item); }} />
                            </FlexItem>

                            <Button appearance="transparent" disabled={!item.user_is_creator} icon={<CopyRegular />} title={item.user_is_creator ? "Duplicate poll" : "Duplicate poll (only for polls that have been created by you)"} onClick={() => { _duplicatePoll(item); }} />


                            <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                    <Button appearance="transparent" icon={<MoreHorizontalFilled />} title="More" />
                                </MenuTrigger>

                                <MenuPopover>
                                    <MenuList>
                                        <MenuItem icon={<EditRegular />} onClick={() => { _editVote(item); }}>Edit vote</MenuItem>
                                        <MenuItem icon={item.poll_is_closed ? <LockOpenRegular /> : <LockClosedRegular />} disabled={!item.user_is_creator} onClick={async () => { _changeOpenClosePoll(item, item.poll_is_closed ? "open" : "close") }}>{item.poll_is_closed ? "Open poll" : "Close poll"}</MenuItem>
                                        <MenuItem icon={<DeleteRegular />} disabled={!item.user_is_creator} onClick={() => { _deletePoll(item); }}>Delete poll</MenuItem>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                        </>
                    )}

                    {appContext.environment.current === EnumAppEnvironment.message_chat && (
                        <>
                            <FlexItem push>
                                <Button appearance="transparent" icon={<SendRegular />} title="Post existing poll" onClick={() => { _viewPoll(item); }} />
                            </FlexItem>

                            {item.user_is_creator && (
                                <Button appearance="transparent" icon={<CopyRegular />} title="Duplicate poll" onClick={() => { _duplicatePoll(item); }} />
                            )}
                        </>

                    )}

                </Flex>
            ),
        }),
    ];

    // Define column sizing options for the ListPolls table (data grid).
    const tableColumnSizingOptions: TableColumnSizingOptions = {
        index: { idealWidth: 40 },
        created_on: { idealWidth: 100 },
        role: { idealWidth: 85 },
        poll: { padding: 0 },
    };


    // Get data from backend
    useEffect(() => {
        const fetchData = async () => {

            const paramEnvironment = (appContext.environment.current === EnumAppEnvironment.personal_tab ? "tab" : "chat");

            const sso_token = (appContext.isSSOEnabled) ? await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, true) : "";

            if (!sso_token && appContext.appAuthData.sso_required) {

                // If not token can be retreived and
                // SSO token is required, show empty list.

                tabContext.listPollResults.current = new ListPollResults();
            } else {
                // Default case: Regulary check for poll list.
                // If SSO is enabled, poll list will show all polls.
                // If SSO is not enabled, poll list will show only polls from last 3 weeks.

                const data = await backendListPolls(appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, sso_token, appContext.appAuthData.lang, paramEnvironment, tabContext.tableSearch.current);
                tabContext.listPollResults.current = data;
            }

            // Update GUI ticker to refresh GUI elements.
            tabContext.setRefreshTickerGUI(refreshTicker);
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabContext.refreshTickerData]);

    return (
        <Segment fillFlex>
            <EmptyFlexRow size="0.5" />
            <Flex column fillFlex gap="small" style={{ maxHeight: (appContext.environment.current === EnumAppEnvironment.personal_tab ? "75vh" : undefined), overflow: "auto" }}>
                <DataGrid
                    id={"ListPollsTable_" + tabContext.refreshTickerGUI /* Use the GUI ticker to force a re-render */}
                    items={tabContext.listPollResults.current.polls}
                    columns={tableColumns}
                    sortable
                    getRowId={(item: PollDataClass) => item.poll_guid}
                    focusMode="composite"
                    resizableColumns
                    resizableColumnsOptions={{ autoFitColumns: true }}
                    columnSizingOptions={tableColumnSizingOptions}
                >
                    <DataGridHeader>
                        <DataGridRow>
                            {({ renderHeaderCell }) => (
                                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                            )}
                        </DataGridRow>
                    </DataGridHeader>

                    <DataGridBody<PollDataClass>>
                        {({ item, rowId }) => (
                            <DataGridRow<PollDataClass> key={rowId} >
                                {({ renderCell }) => (
                                    <DataGridCell>{renderCell(item)}</DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                </DataGrid>

                <EmptyFlexRow size="1.5" />

                {tabContext.listPollResults.current.polls.length >= 100 && (
                    <Text><b>Max Limit: </b>The table can only show 100 polls. Please use the search field to narrow down your results.</Text>
                )}

                {!appContext.isSSOEnabled && (
                    <Flex gap="small" alignItems="center" wrap>
                        {appContext.appAuthData.sso_required && (<><WarningFilled color={tokens.colorPaletteYellowForeground3} /><Text><b>Important: </b>Enhanced authentication is required for your organization. Please authenticate to continue accessing your polls.</Text></>)}
                        {!appContext.appAuthData.sso_required && (<Text><b>Remark: </b>The table shows your polls from the last 3 weeks. Please authenticate to access all of your polls.</Text>)}

                        <Button size="small" onClick={async () => { await _authenticate(); }}>Authenticate</Button>
                    </Flex>
                )}
            </Flex>
            <EmptyFlexRow size="0.5" />
        </Segment>


    );

    async function _authenticate() {
        // Try to get token with popup
        const token = await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, false);

        if (token) {
            // Reload page
            window.location.reload();
        }
    }

    async function _changeOpenClosePoll(item: PollDataClass, action: string) {
        // set active table element
        tabContext.activePollElement.current = item;

        // Call backend to change the poll status
        // We assume that the backend call is successful and update the GUI immediately.
        const responseTask = backendEditPollClient(action, appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, appContext.appAuthData.lang, item.poll_guid, item.poll_secret);

        // GUI fast track: Iterate through tableItems and update the poll that was changed
        tabContext.listPollResults.current.polls = tabContext.listPollResults.current.polls.map((poll) => {
            if (poll.poll_guid === item.poll_guid) {
                poll.poll_is_closed = !poll.poll_is_closed;
            }
            return poll;
        });
        // Update GUI for fast track changes.
        tabContext.setRefreshTickerGUI(refreshTicker);

        // Wait for the final response before refreshing the data from the backend.
        await responseTask;

        // Trigger a refresh of the data from the backend.
        tabContext.setRefreshTickerData(refreshTicker);

    }

    function _deletePoll(item: PollDataClass) {
        // set active table element
        tabContext.activePollElement.current = item;

        // Open the dialog for confirmation
        tabContext.setTabDialog(TabDialogType.DeletePoll);
    }

    function _viewPoll(item: PollDataClass) {
        // set active table element
        tabContext.activePollElement.current = item;

        // Open the dialog for confirmation
        tabContext.setTabDialog(TabDialogType.ViewOrPostPoll);
    }

    function _duplicatePoll(item: PollDataClass) {
        if (item.user_is_creator === false) { return; }

        // set active table element
        tabContext.activePollElement.current = item;

        // Open the dialog for confirmation
        tabContext.setTabDialog(TabDialogType.DuplicatePoll);
    }


    function _editVote(item: PollDataClass) {
        // set active table element
        tabContext.activePollElement.current = item;

        // Open the dialog for confirmation
        tabContext.setTabDialog(TabDialogType.EditVote);
    }


}

function getPollRole(userIsCreator: boolean): string {
    return userIsCreator ? "Creator" : "Voter";
}



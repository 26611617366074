export const empty_guid = "00000000-0000-0000-0000-000000000000";

export enum EnumPolLView {
    not_set = "not_set",
    not_found = "not_found",
    poll = "poll",
    result = "result",
    result_details = "result_details",
    result_thankyou = "result_thankyou"
}

export type TypePollAnswer = {
    seq: number,
    answer_guid: string,
    text: string,
    pre_select: boolean;

    // Result related attributes
    answer_votes: number,
    voter_names: string
};

export class PollDataClass {
    // Actual view related attributes
    public poll_view: EnumPolLView = EnumPolLView.not_set;
    public seq: number = 1;
    public poll_is_closed: boolean = false;
    public hot_reload: boolean = false;

    // Basic poll attributes
    public user_is_creator: boolean = false;
    public poll_guid: string = empty_guid;
    public poll_secret: string = "";
    public source_poll: string = "";
    public created_on: string = "";
    public created_by: string = "";
    public question: string = "";
    public answers: TypePollAnswer[] = [
        { seq: 1, answer_guid: empty_guid, text: "", pre_select: false, answer_votes: 0, voter_names: "" },
        { seq: 2, answer_guid: empty_guid, text: "", pre_select: false, answer_votes: 0, voter_names: "" },
    ];

    // Poll option attributes
    public option_votes_have_limit: boolean = true;
    public option_votes_max_limit: number = 1;
    public option_votes_are_anonymous: boolean = false;

    public option_results_creator_only : boolean = false;

    public option_expires_auto: boolean = false;
    public option_expires_datetime: string = "";

    // Result related attributes
    public poll_votes_total: number = 0;
    public poll_results_restricted: boolean = false;
    public poll_unlocked_until : string | undefined = undefined; // Unlock date (LDT)

    constructor(data?: any) {
        if (data) {
            this.poll_view = data.poll_view ?? this.poll_view;
            this.seq = data.seq ?? this.seq;
            this.poll_is_closed = data.poll_is_closed ?? this.poll_is_closed;
            this.hot_reload = data.hot_reload ?? this.hot_reload;

            this.user_is_creator = data.user_is_creator ?? this.user_is_creator;
            this.poll_guid = data.poll_guid ?? this.poll_guid;
            this.poll_secret = data.poll_secret ?? this.poll_secret;
            this.source_poll = data.source_poll ?? this.source_poll;
            this.created_on = data.created_on ?? this.created_on;
            this.created_by = data.created_by ?? this.created_by;
            this.question = data.question ?? this.question;

            this.answers = data.answers ?? this.answers;

            this.option_votes_have_limit = data.option_votes_have_limit ?? this.option_votes_have_limit;
            this.option_votes_max_limit = data.option_votes_max_limit ?? this.option_votes_max_limit;
            this.option_votes_are_anonymous = data.option_votes_are_anonymous ?? this.option_votes_are_anonymous;

            this.option_results_creator_only = data.option_results_creator_only ?? this.option_results_creator_only;

            this.option_expires_auto = data.option_expires_auto ?? this.option_expires_auto;
            this.option_expires_datetime = data.option_expires_datetime ?? this.option_expires_datetime;

            this.poll_votes_total = data.poll_votes_total ?? this.poll_votes_total;
            this.poll_results_restricted = data.poll_results_restricted ?? this.poll_results_restricted;
            this.poll_unlocked_until = data.poll_unlocked_until ?? this.poll_unlocked_until;

        }
    }
}

export function duplicatePoll(sourcePoll: PollDataClass, hasActiveLicense : boolean): PollDataClass {

    // Copy all attributes form old poll
    const newPoll = new PollDataClass(JSON.parse(JSON.stringify(sourcePoll)));

    // Reset basic attributes to simulate a new poll.
    newPoll.poll_view = EnumPolLView.not_set;
    newPoll.poll_is_closed = false;
    newPoll.poll_guid = empty_guid;
    newPoll.poll_secret = "";
    newPoll.created_on = "";
    newPoll.created_by = "";
    newPoll.poll_results_restricted = false;
    newPoll.poll_votes_total = 0;

    if (!hasActiveLicense){
        // Reset pro features to default.
        newPoll.option_results_creator_only = false;
    }

    // Set the source for the new poll
    newPoll.source_poll = sourcePoll.poll_guid;

    return newPoll;
}

export function pollExists(poll: PollDataClass): boolean {
    if (!poll.poll_guid || !poll.poll_secret) { return false; }
    if (poll.poll_guid == empty_guid) { return false; }

    return true;
}


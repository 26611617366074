import { Dialog, Button, DialogSurface, DialogTitle, DialogContent, Text, DialogActions, Image, DialogBody, Spinner } from "@fluentui/react-components";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Flex, FlexItem } from "sub/blocks/FlexBoxes";
import { TabContext, TabContextClass, TabDialogType } from "sub/context/TabContext";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { PollDataClass } from "sub/bot-client-shared/PollDataClass";
import { EnumPollWorkflow, PollContext, PollContextClass } from "sub/context/PollContext";
import { PollCreateOrEdit } from "./PollCreateOrEdit";
import { PollPreview } from "./PollPreview";
import { dialog_close_refresh, proceedFromDuplicateToNewPollEntry, refreshTicker } from "sub/library/epCommon";
import { backendGetPollOrResultClient } from "sub/library/epBackend";
import { AppContext, AppContextClass } from "sub/context/AppContext";
import { hyDebug, hyDebugLocal } from "sub/library/hyDebug";

export function TabDialogCreateDraft() {
    const tabContext = useContext(TabContext);
    const appContext = useContext(AppContext);

    // Define context
    const [contextPollData, setContextPollData] = useState(new PollDataClass());
    const updatePollData = (updates: Partial<PollDataClass>) => {
        setContextPollData(current => ({ ...current, ...updates }));
    };

    // Start with New Poll Entry or Duplicate Poll Loading:
    const [pollWorkflow, setPollWorkflow] = useState(tabContext.tabDialog == TabDialogType.DuplicatePoll ? EnumPollWorkflow.duplicatePoll_loading : EnumPollWorkflow.newPoll_entry);

    const pollContext: PollContextClass =
    {
        pollWorkflow: pollWorkflow,
        setPollWorkflow: setPollWorkflow,

        pollData: contextPollData,
        updatePollData: updatePollData,
    };

    // One time code only for tab mode.
    useEffect(() => {
        if (!appContext.appAuthData.hasActiveLicense) {
            // Refresh authorization data once
            // This will update the poll limit warning in the GUI
            appContext.setAuthRefreshTicker(refreshTicker);
        }
    }, []);


    // Poll Workflow handling
    useEffect(() => {

        // Close dialog when poll workflow is finished.
        if (pollWorkflow === EnumPollWorkflow.close) {
            tabContext.setTabDialog(TabDialogType.None);
        }

        // Duplicate the poll and proceed with new poll entry.
        if (pollWorkflow === EnumPollWorkflow.duplicatePoll_loading) {
            const fetchData = async () => {
                await proceedFromDuplicateToNewPollEntry(tabContext, appContext, setPollWorkflow, setContextPollData);
            }

            fetchData();
        }

        // Close dialog when poll workflow is finished.
        // Also update data for tab.
        if (pollWorkflow === EnumPollWorkflow.newPoll_preview_close) {

            // Add pollData to listPollResults, transform for table and update GUI immediately.
            tabContext.listPollResults.current.polls.unshift(contextPollData);
            tabContext.setRefreshTickerGUI(refreshTicker);

            // Initiate real data update.
            tabContext.setRefreshTickerData(refreshTicker);
            tabContext.setTabDialog(TabDialogType.None);
        }

    }, [pollWorkflow]);

    const dialogTitle = (pollWorkflow == EnumPollWorkflow.newPoll_preview || pollWorkflow == EnumPollWorkflow.newPoll_preview_close) ? "Preview" : "Draft a new poll";

    return (
        <PollContext.Provider value={pollContext}>
            <Dialog defaultOpen onOpenChange={(event, data) => { if (!data.open) { dialog_close_refresh(tabContext); } }}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle action={<Button appearance="subtle" aria-label="close" onClick={() => { dialog_close_refresh(tabContext); }} icon={<Dismiss24Regular />} />}>{dialogTitle}</DialogTitle>
                        <DialogContent style={{ maxHeight: "70vh" }}>
                            <Flex column fillFlex gap="smaller">

                                {(pollWorkflow === EnumPollWorkflow.duplicatePoll_loading) &&
                                    <Flex column fillFlex justifyContent="center">
                                        <Spinner />
                                    </Flex>
                                }

                                {(pollWorkflow === EnumPollWorkflow.newPoll_entry || pollWorkflow === EnumPollWorkflow.close) && <PollCreateOrEdit />}

                                {(pollWorkflow === EnumPollWorkflow.newPoll_preview || pollWorkflow === EnumPollWorkflow.newPoll_preview_close) && <PollPreview />}

                            </Flex>

                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </PollContext.Provider>
    );


}

function pollWorkflowDuplicateToNewPollEntry(tabContext: TabContextClass, appContext: AppContextClass, setPollWorkflow: Dispatch<SetStateAction<EnumPollWorkflow>>, updatePollData: (updates: Partial<PollDataClass>) => void) {
    throw new Error("Function not implemented.");
}
